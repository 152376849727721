#GameBoard {
	position: relative;
	margin: auto;
	border-style: solid;
	color: #fff;
}

#GameBoard .Block {
	position: absolute;
}

#GameBoard #GameOver {
	text-align: center;
	height: 100%;
	flex-direction: column;
	display: flex;
	justify-content: space-around;
}

#GameBoard #Score {
	position: relative;
	top: 105%;
	text-align: center;
	font-weight: bold;
	color: #fff;
}

#GameBoard #FinalScore {
	text-align: center;
	color: #fff;
}

#GameBoard #GameOverText {
	color: #ca0000;
	font-weight: bold;
	text-decoration-line: underline;
}

#GameBoard #PressSpaceText {
	color: #2e7ecb;
	font-weight: bold;
}